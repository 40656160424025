// extracted by mini-css-extract-plugin
export var buttonChat = "hero-module--buttonChat--24b64";
export var content = "hero-module--content--fa09a";
export var description = "hero-module--description--5061c";
export var hero = "hero-module--hero--ab416";
export var job = "hero-module--job--4fcdc";
export var modalHead = "hero-module--modalHead--d7131";
export var modalHeadText = "hero-module--modalHeadText--2abe9";
export var modalTeam = "hero-module--modalTeam--505b2";
export var photo = "hero-module--photo--ed310";
export var wrapName = "hero-module--wrap-name--5529b";