// extracted by mini-css-extract-plugin
export var company = "skills-module--company--7b598";
export var experience = "skills-module--experience--dc6ee";
export var language = "skills-module--language--c0c5e";
export var languageItem = "skills-module--languageItem--20fa6";
export var languageName = "skills-module--languageName--f0563";
export var lineExperience = "skills-module--lineExperience--11e9e";
export var lineSkill = "skills-module--lineSkill--4313a";
export var personalSkills = "skills-module--personalSkills--d84bc";
export var portfolio = "skills-module--portfolio--52e64";
export var skillGrid = "skills-module--skillGrid--70727";
export var skillHard = "skills-module--skillHard--f1b24";
export var skills = "skills-module--skills--e44c2";
export var time = "skills-module--time--41475";
export var title = "skills-module--title--f5e84";
export var url = "skills-module--url--810f8";
export var work = "skills-module--work--bb989";
export var wrap = "skills-module--wrap--3ff71";