import * as React from "react";
import * as style from "./skills.module.scss";
import { Block } from "./skillBlock/skillBlock";
import { List } from "./list/list";

export const Skills = (props) => {
  const {
    languageTitle,
    englishTitle,
    englishLevel,
    otherLanguage,
    mainTitle,
    additionalTitle,
    hardSkills,
    workExperience,
    portfolio
  } = props.info;
  return (
    <div className={style.skills}>
      {hardSkills && (
        <Block title={"Hard Skills "} full classes={style.personalSkills}>
          <>
            {hardSkills.main && (
              <List
                classes="skillMain"
                title={mainTitle}
                content={hardSkills.main}
              />
            )}
            {hardSkills.additional && (
              <List title={additionalTitle} content={hardSkills.additional} />
            )}
          </>
        </Block>
      )}

      <div className={style.skillGrid}>
        {typeof workExperience === "object" && workExperience.length > 0 && (
          <Block classes={style.experience} title={"work experience"}>
            {workExperience.map((i, key) => {
              return (
                <div className={style.wrap} key={key}>
                  <div className={style.company}>
                    <h4>{i.company}</h4>
                    <p className={style.time}>{i.year}</p>
                  </div>
                  <p className={style.work}>{i.position}</p>
                </div>
              );
            })}
          </Block>
        )}
        <Block classes={style.language} title={languageTitle}>
          <div className={style.languageItem}>
            <p className={style.languageName}>{englishTitle}</p>
            <p>{englishLevel}</p>
          </div>
          {otherLanguage &&
            otherLanguage?.map((i, key) => {
              return (
                <div className={style.languageItem} key={key}>
                  <p className={style.languageName}>{i.text}</p>
                  <p>{i.level}</p>
                </div>
              );
            })}
        </Block>
      </div>

      {typeof portfolio === "object" && portfolio.length > 0 && (
        <Block classes={style.portfolio} title={"Portfolio"}>
          {portfolio.map((i, key) => {
            return (
              <div className={style.wrap} key={key}>
                <div>
                <h4 className={style.title}>{i.name}</h4>
                <p className={style.description}>{i.Description}</p>
                </div>
                <a href={i.url} title={i.url} target={'_blank'} rel={'noopener'} className={style.url}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 640 512">
                    <path
                      d="M579.8 267.7A144.6 144.6 0 0 0 393.5 47.8l-1.6 1.1a32 32 0 1 0 37.2 52l1.6-1.1a80.5 80.5 0 0 1 103.8 122.6L422.3 334.8A80.6 80.6 0 0 1 299.7 231l1.1-1.6a32 32 0 0 0-52-37.2l-1.1 1.6A144.6 144.6 0 0 0 467.5 380l112.3-112.3zM60.2 244.3a144.6 144.6 0 0 0 186.3 219.9l1.6-1.1a32 32 0 1 0-37.2-52l-1.6 1.1a80.7 80.7 0 0 1-103.8-122.7l112.2-112.3a80.6 80.6 0 0 1 122.6 103.9l-1.1 1.6a32 32 0 0 0 52 37.2l1.1-1.6A144.4 144.4 0 0 0 172.5 132L60.2 244.3z" fill={'currentColor'}/>
                  </svg>
                </a>
              </div>
            );
          })}
        </Block>
      )}
    </div>
  );
};
